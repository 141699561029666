import React, { Component } from "react";
import {
  ExampleContainer
} from "./exampleHandler.styles";
import useExternalScripts from "./useExternalScripts";


export function animateText(textArea) {
  let text = textArea.value;
  let to = text.length,
    from = 0;

  // animate({
  //   duration: 5000,
  //   timing: bounce,
  //   draw: function(progress) {
  //     let result = (to - from) * progress + from;
  //     textArea.value = text.slice(0, Math.ceil(result))
  //   }
  // });
}

export function bounce(timeFraction) {
  for (let a = 0, b = 1; 1; a += b, b /= 2) {
    if (timeFraction >= (7 - 4 * a) / 11) {
      return -Math.pow((11 - 6 * a - 11 * timeFraction) / 4, 2) + Math.pow(b, 2)
    }
  }
}

export class ExampleHandler extends Component {
  constructor(props) {
    super(props)
    
    // useExternalScripts("https://js.cx/libs/animate.js");
    this.state = {
      
    }
  }

  render() {
    return (
      <div>
        <ExampleContainer>
        <textarea id="textExample">
          1. German
          1.1. Bavarian
          1.2. Frisian
          2.3. Limburgish
          2.4. Luxemburgish
          2. Dutch
          2.1. Dutch Low Saxon
          2.2. Limburgish
          3. Danish
          4. Swedish
        </textarea>
        animateText(textExample)
        </ExampleContainer>
      </div>
    )
  }
}
