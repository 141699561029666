import React, { useRef, useState, Component } from "react";
// import SaveIcon from '@material-ui/icons/Save';
import {
    FileUploadContainer,
    FileDownloadContainer,
    FormField,
    DragDropText,
    UploadFileBtn,
    UploadDownloadButton,
    InputLabel
  } from "./file-upload.styles";

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000;

export class DataHandler extends Component {
  constructor(props) {
    super(props)

    this.state = {
      uploadButtonName: 'Upload file',
      UploadDownloadButtonName: 'Download zip',
      UploadDownloadButtonStatus: false,
      downloadFileName: '',
      fileInputField: React.createRef(),
      acceptFormat: props.accept
    }
  }

  setDownloadFileName = (name) => {
    this.setState({ downloadFileName: name });
    if(name.length>0) {
      this.setState({ UploadDownloadButtonStatus: true });
    }else{
      this.setState({ UploadDownloadButtonStatus: false });
    }
  }

  handleUploadBtnClick = () => {
    this.state.fileInputField.current.click();
  };

  handleNewFileUpload = (e) => {
    // Upload here and check response file size with current file size
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    const request = async () => {
      const response = await fetch('https://d2f.monellimankeimontenegro.eu/upload', {
        method: 'POST',
        body: formData
      })
      .then(response => response.json())
      .then(data => ({  fileStatus: data.Status,
                        fileSize: data.Size,
                        fileName: data.Name } ));
      return response;
      }

    // Get file name for queryString 
    request().then( response => (
      fetch('https://d2f.monellimankeimontenegro.eu/folderbau?name=' + response.fileName)
      // fetch('http://localhost:8000/folderbau?name=' + response.fileName)
      .then(resp => resp.json())
      .then(data => (this.setDownloadFileName(data.Name))))
    )
    
  };

  handleDownloadBtnClick = (fileName) => {
    fetch("output/" + fileName).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      });
    });
    this.setDownloadFileName("");
  };

  render() {
    return (
      <div>
        <FileUploadContainer>
        <DragDropText>Drag and drop your file anywhere</DragDropText>
        <UploadDownloadButton
          type="button" 
          onClick={this.handleUploadBtnClick}
          variant="contained"
          size="large"
          color="default">
          <span> {this.state.uploadButtonName} </span>
        </UploadDownloadButton>
        </FileUploadContainer>
        <FormField
          type="file"
          ref={this.state.fileInputField}
          onChange={this.handleNewFileUpload}
          title=""
          value=""
          accept={this.state.acceptFormat}
        />

        <FileDownloadContainer>
        <DragDropText>Download zip file with folders</DragDropText>
        <UploadDownloadButton
          disabled={!this.state.UploadDownloadButtonStatus}
          type="button"
          onClick={() => this.handleDownloadBtnClick(this.state.downloadFileName)}
          variant="contained"
          size="large"
          color="default"
        >
        <span> {this.state.UploadDownloadButtonName} </span>
        </UploadDownloadButton>
        </FileDownloadContainer>
      </div>
    )
  }
}










export const FileUpload = ({
  label,
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  ...otherProps
  }) => {
  const fileInputField = useRef(null);
  var [downloadFileName, setDownloadFileName] = useState("");

  const handleUploadBtnClick = () => {
      fileInputField.current.click();
    };

  const handleNewFileUpload = (e) => {
    // Upload here and check response file size with current file size
    // fetch('http://d2f.monellimankeimontenegro.eu/upload') http://localhost:8000/upload
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    const request = async () => {
      const response = await fetch('https://d2f.monellimankeimontenegro.eu/upload', {
        method: 'POST',
        body: formData
      })
      .then(response => response.json())
      .then(data => ({  fileStatus: data.Status,
                        fileSize: data.Size,
                        fileName: data.Name } ));
      return response;
      }

    // Get file name for queryString http://localhost:8000/folderbau?name
    request().then( response => (
      fetch('https://d2f.monellimankeimontenegro.eu/folderbau?name='+response.fileName)
      .then(resp => resp.json())
      .then(data => (setDownloadFileName(data.Name))))
    )
    
  };

  const handleDownloadBtnClick = (fileName) => {
    fetch("output/" + fileName).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      });
    });
    setDownloadFileName("");
  };

  return (
  <>
    <FileUploadContainer>
      <InputLabel>{label}</InputLabel>
      <DragDropText>Drag and drop your file anywhere or</DragDropText>
      <UploadFileBtn 
        type="button" 
        onClick={handleUploadBtnClick}>
        <i className="fas fa-file-upload" />
        <span> Upload {otherProps.multiple ? "files" : "a file"}</span>
      </UploadFileBtn>
      <FormField
        type="file"
        ref={fileInputField}
        onChange={handleNewFileUpload}
        title=""
        value=""
        {...otherProps}
      />
    </FileUploadContainer>
    <button disabled={!downloadFileName.length>0} type="button" onClick={() => handleDownloadBtnClick(downloadFileName)}>
        <i className="fas fa-file-upload" />
        <span> Download </span>
    </button>

  </>
  );
}

export default FileUpload;