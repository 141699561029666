import React from "react";
import styled from "styled-components";
import { Responsive, WidthProvider } from "react-grid-layout";
import '../Body.css';
import {
  DataHandler,
} from "./fileUpload/file-upload.component";
import {
  ExampleHandler
} from "./exampleHandler/exampleHandler.component";


const GridItemWrapper = styled.div`
  // background: #f5f5f5;
`;

const GridItemContent = styled.div`
  // padding: 8px;
`;

const ResponsiveGridLayout = WidthProvider(Responsive);

function Body() {
  const layout = [
    {i: 'left-part', x: 0, y: 0, w: 1, h: 2},
    {i: 'right-part', x: 1, y: 0, w: 1, h: 4}
  ];  
  return (
    <div className="Body">      
      <ResponsiveGridLayout 
        className="layout" 
        layouts={{ lg: layout }}
        breakpoints={{ lg: 800, md: 700, sm: 600, xs: 480, xxs: 0 }}
        cols={{ lg: 2, md: 2, sm: 1, xs: 1, xxs: 1 }}
        rowHeight={100}
        width={1000}>
        <div key="left-part"><DataHandler accept=".docx,.txt"></DataHandler></div>
        <GridItemWrapper key="right-part">
          <GridItemContent>
          <ExampleHandler></ExampleHandler>
          </GridItemContent>
        </GridItemWrapper>
      </ResponsiveGridLayout>
    </div>
  );
}

export default Body;
