import React from "react";
import '../Header.css';

function Header() {
  return (
    <header className="header">
      <img src="/logo64.png" alt="MMM logo" className="logo" />
      <h1> d2f - document to folder</h1>
      <nav>
        <a href="/">Home</a>
        <a href="/impressum">Impressum</a>
      </nav>
    </header>
  );
}

export default Header;
