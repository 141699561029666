import styled from "styled-components";

export const ExampleContainer = styled.section`
  position: relative;
  margin: 15px 15px 15px 15px;
  border: 2px solid #1100ff;
  padding: 20px 10px 20px 10px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: 255px;
  align-items: center;
  background-color: transparent;
`;
